import React from "react"
import Img from "../images/about.webp"
import Seo from "../components/seo"
import Navbar from "../components/navbar"
import Header from "../components/header"
import Contact from "../components/contact"
import Footer from "../components/footer"

const About = () => {
  return (
    <div>
      <Seo title="About" />
      <Navbar />
      <Header />
      <section className="about section bd-container" id="about">
        <div className="about__container bd-grid">
          <img src={Img} alt="About" className="about__img" />
          <div className="about__data">
            <h1 className="about__subtitle">PT. Whrilindo Indonesia Perkasa</h1>
            <p className="about__description">
              is a Turbine Ventilator and Plywood Distributor which was
              established in 2019 and based in Batam, Indonesia. Our vision is
              to be customer's preferred partner and our mission is to provide
              the best quality product and services by prioritizing customer
              satisfaction.
            </p>
          </div>
        </div>
      </section>
      <Contact />
      <Footer />
    </div>
  )
}

export default About
